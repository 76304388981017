@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}

@layer base {
  :root {
    --background: #f4f4f4;
    --foreground: #000;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: #edf6ea;
    --primary-foreground: #64b548;
    --secondary: #edeff5;
    --secondary-foreground: #474d66;

    --muted: #fff;
    --muted-foreground: #646464;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: #f8e3da;
    --destructive-foreground: #85462b;

    --border: 214.3 31.8% 91.4%;
    --input: #c3c3c3;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }
}

@layer components {
  .form-group {
    @apply w-[290px];
  }
  .form-control-input {
    @apply h-[38px] w-full rounded border border-[#C3C3C3] bg-white px-3 text-[13px] focus:border-green focus:outline-none focus:ring-0;
  }
  .form-control-textarea {
    @apply w-full rounded border border-[#C3C3C3] bg-white px-3 py-2 text-[13px] focus:border-green focus:outline-none focus:ring-0;
  }
  .form-control-label {
    @apply mb-1 block text-sm;
  }
  .submit-button {
    @apply flex h-[38px] items-center rounded bg-[#64B548] px-4 text-sm text-white disabled:cursor-not-allowed disabled:opacity-80;
  }
  .form-check-input {
    @apply mr-1;
  }
  .form-check-label {
    @apply mr-3 inline-flex;
  }
}

/* TTHoves Font */
@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts//TTHoves-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-DemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-DemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts//TTHoves-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TT Hoves';
  src: url('./assets/fonts/TTHoves-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

/* React DatePicker Styles */
.react-datepicker {
  color: #3d3d3d !important;
  border: 1px solid #c3c3c3 !important;
}

.react-datepicker__day--in-range {
  background-color: #20ac76 !important;
}

.react-datepicker__day--in-selecting-range {
  background-color: RGBA(72, 169, 40, 0.2) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #20ac76 !important;
}

.react-datepicker__header {
  background-color: #f4f4f4 !important;
}

.react-datepicker__close-icon::after {
  content: 'x' !important;
  font-size: 10px !important;
  background-color: #20ac76 !important;
  color: white !important;
  vertical-align: baseline !important;
}

/* Radix Popover styles */
.PopoverContent {
  background-color: white;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
}
.PopoverContent[data-state='open'][data-side='top'] {
  animation-name: slideDownAndFade;
}
.PopoverContent[data-state='open'][data-side='right'] {
  animation-name: slideLeftAndFade;
}
.PopoverContent[data-state='open'][data-side='bottom'] {
  animation-name: slideUpAndFade;
}
.PopoverContent[data-state='open'][data-side='left'] {
  animation-name: slideRightAndFade;
}
@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
