// nav a:hover {
//   border-radius: 4px;
//   background-color: #edf6ea;
//   color: #48a928;
// }

// .active {
//   border-radius: 4px;
//   background-color: #edf6ea;
//   color: #48a928;
// }

nav a {
  transition: all 3s ease-in;
  span {
    &.active-icon,
    &.active-chevron {
      display: none;
    }
  }
  &:hover,
  &.active {
    border-radius: 4px;
    background-color: #edf6ea;
    span {
      color: #48a928;

      &.active-icon,
      &.active-chevron {
        display: block;
      }
      &.icon,
      &.chevron {
        display: none;
      }
    }
  }
}
